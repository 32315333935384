import { BAKC_ADDRESS, BAYC_ADDRESS, MAYC_ADDRESS } from 'modules/bend/constants'
import { ChainId } from 'modules/wallet-module'

const DEFAULT_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID || '5'

const ADDRESSES: any = {
  [ChainId.MAINNET]: {
    BEND_STAKE_MANAGER_ADDRESS: '0x9d1833becb105405a8305F775F0aF3157d12B33c',
    BEND_NFT_POOL_ADDRESS: '0x9Da9571389BA2316ad98f695210aD5fB0363cDEd',
    BEND_COIN_POOL_ADDRESS: '0x86862445f58F50382c421a7bceCBcd971cc25367',
    STAKED_BAYC_ADDRESS: '0x08f5F0126aF89B4fD5499E942891D904A027624B',
    STAKED_MAYC_ADDRESS: '0xc1ED28E4b4d8e284A41E7474CA5522b010f3A64F',
    STAKED_BAKC_ADDRESS: '0xf6d3B8098967dd349d0477F01C16E8864a832ac2',
    BOUND_STAKED_BAYC_ADDRESS: '0x065D630ecB814653dd1dc202E5A081CD021927fA',
    BOUND_STAKED_MAYC_ADDRESS: '0x526936466211af60b703B054726a759FF8fEa195',
    COMPOUND_V1_MIGRATOR_ADDRESS: '0x8A25930D704B860aDf2458AA66eaC5290Ea74f51',
    POOL_VIEWER_ADDRESS: '0xA6897FE1EA51bcb5E245fAcA1067084Fd6ba4489',
    APECOIN_PRICE_FEED: '0xc7de7f4d4C9c991fF62a07D18b3E31e349833A18',
    LENDING_MIGRATOR_ADDRESS: '0x968c9090a217786f414025ceF11540f801f38ee1',
    AAVE_LEND_POOL_ADDRESS: '0x7d2768dE32b0b80b7a3454c06BdAc94A69DDc7A9',
    BEND_NFT_VAULT: '0xaAa2DA255DF9Ee74C7075bCB6D81f97940908A5D'
  },
  [ChainId.SEPOLIA]: {
    BEND_STAKE_MANAGER_ADDRESS: '0xEEE4f9bdbBC0f261DeaD86E0BAC441D3E3Ae7ac6',
    BEND_NFT_POOL_ADDRESS: '0x4d88286FB725d3bd094d2D389Cf6e8518adf5a05',
    BEND_COIN_POOL_ADDRESS: '0xe8fd314458e7a3E1013AB259a5cDd9d8170ffF43',
    STAKED_BAYC_ADDRESS: '0x214455B76E5A5dECB48557417397B831efC6219b',
    STAKED_MAYC_ADDRESS: '0xE5165Aae8D50371A277f266eC5A0E00405B532C8',
    STAKED_BAKC_ADDRESS: '0x7354e69221F33291c5993721fb762d39ecc239A5',
    BOUND_STAKED_BAYC_ADDRESS: '0xCa70E249482E2Df96b21993d54565Af76e54BD0e',
    BOUND_STAKED_MAYC_ADDRESS: '0xCCAA3624a7473649D2d7a5d7400513F04fc50bdE',
    COMPOUND_V1_MIGRATOR_ADDRESS: '0x60C7eb6362D4fD6dFa53f6610784924eFaA1d178',
    POOL_VIEWER_ADDRESS: '0xa9A3b239CC1C2Fc9D40E6258464a9c180aaA3A19',
    APECOIN_PRICE_FEED: '', // not used in DEV environment
    LENDING_MIGRATOR_ADDRESS: '0x94C4C2eA9e68cDA0D999A915310A1E07880c9Da2',
    AAVE_LEND_POOL_ADDRESS: '0x8cfb1B0Beaa98a2b460D431e486f018eB59CfF1D',
    BEND_NFT_VAULT: '0x1f9b18D502d8A406564b0cF4e4B7ad9d4eEb2a52'
  }
}

export const BEND_STAKE_MANAGER_ADDRESS = process.env.NEXT_PUBLIC_BEND_STAKE_MANAGER_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_STAKE_MANAGER_ADDRESS
export const BEND_NFT_POOL_ADDRESS = process.env.NEXT_PUBLIC_BEND_NFT_POOL_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_NFT_POOL_ADDRESS
export const BEND_COIN_POOL_ADDRESS = process.env.NEXT_PUBLIC_BEND_COIN_POOL_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_COIN_POOL_ADDRESS

export const STAKED_BAYC_ADDRESS = process.env.NEXT_PUBLIC_STAKED_BAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].STAKED_BAYC_ADDRESS
export const STAKED_MAYC_ADDRESS = process.env.NEXT_PUBLIC_STAKED_MAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].STAKED_MAYC_ADDRESS
export const STAKED_BAKC_ADDRESS = process.env.NEXT_PUBLIC_STAKED_BAKC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].STAKED_BAKC_ADDRESS

export const BOUND_STAKED_BAYC_ADDRESS = process.env.NEXT_PUBLIC_BOUND_STAKED_BAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BOUND_STAKED_BAYC_ADDRESS
export const BOUND_STAKED_MAYC_ADDRESS = process.env.NEXT_PUBLIC_BOUND_STAKED_MAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BOUND_STAKED_MAYC_ADDRESS

export const COMPOUND_V1_MIGRATOR_ADDRESS = process.env.NEXT_PUBLIC_COMPOUND_V1_MIGRATOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].COMPOUND_V1_MIGRATOR_ADDRESS

export const POOL_VIEWER_ADDRESS = process.env.NEXT_PUBLIC_POOL_VIEWER_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].POOL_VIEWER_ADDRESS

export const APECOIN_PRICE_FEED = process.env.NEXT_PUBLIC_APECOIN_PRICE_FEED || ADDRESSES[DEFAULT_CHAIN_ID].APECOIN_PRICE_FEED

export const LENDING_MIGRATOR_ADDRESS = process.env.NEXT_PUBLIC_LENDING_MIGRATOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].LENDING_MIGRATOR_ADDRESS
export const AAVE_LEND_POOL_ADDRESS = process.env.NEXT_PUBLIC_AAVE_LEND_POOL_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].AAVE_LEND_POOL_ADDRESS

export const BEND_NFT_VAULT = process.env.NEXT_PUBLIC_BEND_NFT_VAULT || ADDRESSES[DEFAULT_CHAIN_ID].BEND_NFT_VAULT

export enum APE_V2_POOL_ADDRESS {
  'BAYC' = BAYC_ADDRESS,
  'MAYC' = MAYC_ADDRESS,
  'BAKC' = BAKC_ADDRESS
}

export enum APE_V2_STAKED_POOL_ADDRESS {
  'BAYC' = STAKED_BAYC_ADDRESS,
  'MAYC' = STAKED_MAYC_ADDRESS,
  'BAKC' = STAKED_BAKC_ADDRESS
}
