import { ChainId } from 'wallet-module'

import AIRDROP_DISTRIBUTION_CONTRACT_ABI from './abis/AirdropDistribution.json'
import BEND_COMPETITION_ABI from './abis/BendCompetition.json'
import BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI from './abis/BendProtocolIncentivesController.json'
import BNFT_ABI from './abis/BNFT.json'
import BOUND_PUNK_GATEWAY_ABI from './abis/BoundPunkGateway.json'
import CRYPTO_PUNKS_MARKET_ABI from './abis/CryptoPunksMarket.json'
import DEBT_TOKEN_ABI from './abis/DebtToken.json'
import DELEGATE_CASH_ABI from './abis/DelegateCash.json'
import EIP2981_ABI from './abis/EIP2981.json'
import ENS_PUBLIC_RESOLVER_ABI from './abis/ens-public-resolver.json'
import ENS_ABI from './abis/ens-registrar.json'
import FEE_DISTRIBUTOR_ABI from './abis/FeeDistributor.json'
import ERC721_ABI from './abis/IERC721Detailed.json'
import LEND_POOL_ABI from './abis/ILendPool.json'
import PUNK_GATEWAY_ABI from './abis/IPunkGateway.json'
import WETH_GATEWAY_ABI from './abis/IWETHGateway.json'
import WRAPPED_PUNKS_ABI from './abis/IWrappedPunks.json'
import KODA_WRAPPER_ABI from './abis/KodaWrapper.json'
import LEND_POOL_ADDRESS_PROVIDER_ABI from './abis/LendPoolAddressesProvider.json'
import LEND_POOL_LOAN_ABI from './abis/LendPoolLoan.json'
import LEND_POOL_SWAP_ABI from './abis/LendPoolSwap.json'
import MERKLE_DISTRIBUTOR_ABI from './abis/MerkleDistributor.json'
import MULTI_CALL_NONPAYABLE_ABI from './abis/MulticallNonpayable.json'
import BEND_STAKE_ABI from './abis/StakedBUNI.json'
import UI_POOL_DATA_PROVIDER_ABI from './abis/UiPoolDataProvider.json'
import UNISWAP_PAIR_ABI from './abis/UniswapV2Pair.json'
import VE_BEND_ABI from './abis/VeBend.json'
import WETH_ABI from './abis/Weth.json'

const DEFAULT_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID || '5'

const ADDRESSES: any = {
  [ChainId.MAINNET]: {
    FEE_DISTRIBUTOR_ADDRESS: '0x2338D34337dd0811b684640de74717B73F7B8059',
    VE_BEND_ADDRESS: '0xd7e97172C2419566839Bf80DeeA46D22B1B2E06E',
    MERKLE_DISTRIBUTOR_ADDRESS: '0x1b5d2904bE3E4711a848Be09b17dEE89E6A5BC27',
    LEND_POOL_ADDRESSES_PROVIDER: '0x24451F47CaF13B24f4b5034e1dF6c0E401ec0e46',
    LEND_POOL: '0x70b97A0da65C15dfb0FFA02aEE6FA36e507C2762',
    LEND_POOL_LOAN: '0x5f6ac80CdB9E87f3Cfa6a90E5140B9a16A361d5C',
    UI_POOL_DATA_PROVIDER: '0x5250cCE48E43AB930e45Cc8E71C87Ca4B51244cf',
    WETH_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000001',
    PUNK_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000002',
    WETH_ADDRESS: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    USDT_ADDRESS: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    BEND_ADDRESS: '0x0d02755a5700414B26FF040e1dE35D337DF56218',
    CRYPTOPUNKS_ADDRESS: '0xb47e3cd837dDF8e4c57F05d70Ab865de6e193BBB',
    WPUNKS_ADDRESS: '0xb7F7F6C52F2e2fdb1963Eab30438024864c313F6',
    BWETH: '0xeD1840223484483C0cb050E6fC344d1eBF0778a9',
    BDebtWETH: '0x87ddE3A3f4b629E389ce5894c9A1F34A7eeC5648',
    BUSDT: '0x9631C79BfD6123A5B53307B6cdfb35F97606F954',
    BDebtUSDT: '0x02716C55f49A9107467507B82F9889480949afE4',
    BEND_COMPETITION: '0xA1756D785F4abD4ec06dB71c65f18D25083CbF88',
    BEND_PROTOCOL_INCENTIVES_CONTROLLER: '0x26FC1f11E612366d3367fc0cbFfF9e819da91C8d',
    BEND_ETH_UNISWAP_POOL: '0x336ef4e633b1117DCA08C1a57f4139C62c32c935',
    BEND_COLLECTOR: '0x43078AbfB76bd24885Fd64eFFB22049f92a8c495',
    BOUND_PUNK_GATEWAY: '0x9f3A8d7F61F6407190fe5264ad88F88F39e214a8k',
    AIRDROP_DISTRIBUTION_CONTRACT: '0x6D187449A5664DD87E58A9d3b982989AaeA469BD',
    BEND_STAKE: '0x647C509AF2A2b2294bB79fCE12DaEc8e7cf938f7',
    BEND_EXCHANGE: '0x7e832eC8ad6F66E6C9ECE63acD94516Dd7fC537A',
    AUTHORIZATION_MANAGER: '0x422b1179b63F9327BEdedeA64B75C942744dFaC8',
    REDEEM_NFT: '0xeD16f45Db8A7eBF86A37F7159FC09cE94490e03D',
    STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE: '0xC0EE83Ae7a0B6a188bAbd8c9F1958311A7703C58',
    ROYALTY_FEE_MANAGER: '0xe377f6eF3E86E532a49625F866750306A478245F',
    DOWNPAYMENT: '0x3710D54de90324C8BA4B534D1e3F0fCEDc679ca4',
    DOWNPAYMENT_BEND_EXCHANGE_ADAPTER: '0xfD8F1f5AAA388ed3bF28B8Adc91D45c8B9db1384',
    DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER: '0xe276C2EeD4Fbf6045BA3AdB0783b0BAf9C6F5cD1',
    DOWNPAYMENT_PUNK_ADAPTER: '0x95c9ee5c9DBfC187743AFF7Af0F647821e95066D',
    DOWNPAYMENT_SEAPORT_ADAPTER: '0x8B5ABF01b87f87Fb8e0FfC60D32ed7DD29b1f06b',
    DOWNPAYMENT_X2Y2_ADAPTER: '0xc384bBd0a25DBE353dd8a09073D636744cFad15B',
    BEND_APE_STAKING_ADDRESS: '0x044A97AC566F348B9f229601F1240E5d1758013A',
    STAKE_MANAGER_ADDRESS: '0xDAFCe4AcC2703A24F29d1321AdAADF5768F54642',
    APECOIN_ADDRESS: '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
    APECOIN_ADDRESS_MAINNET: '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
    BAYC_ADDRESS: '0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D',
    MAYC_ADDRESS: '0x60E4d786628Fea6478F785A6d7e704777c86a7c6',
    BAKC_ADDRESS: '0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623',
    APECOIN_STAKING_ADDRESS: '0x5954aB967Bc958940b7EB73ee84797Dc8a2AFbb9',
    BEND_APECOIN_ADDRESS: '0xEB3837c611fb2C5550F816f227D85262f0d04A52',
    OTHERDEED_ADDRESS: '0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258',
    WKODA_ADDRESS: '0x45676F1a69E173cd5421582eaaCbb9C8c0F28189',
    KODA_GATEWAY: '0x56ECe8EDba529943b9A8ED966253b1F5ac54BF55',
    APE_ETH_UNISWAP_POOL: '0xAc4b3DacB91461209Ae9d41EC517c2B9Cb1B7DAF',
    DELEGATE_CASH_ADDRESS: '0x00000000000076A84feF008CDAbe6409d2FE638B',
    LEND_POOL_SWAP_ADDRESS: '0x7DBFbd828c12442563e4037AF84B99C14e8EAcd1',
    NATIVE_ORG_API_KEY: '1019abc4c050bfdb6a052677d0e269e2d743b895',
    WBTC_ADDRESS: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    WSTETH_ADDRESS: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0'
  },
  [ChainId.SEPOLIA]: {
    FEE_DISTRIBUTOR_ADDRESS: '0x9f232cc0A80FB7489F7214df8B5Cfd693321239E',
    VE_BEND_ADDRESS: '0xc3e9ec69E2D24020D82B602a16C6f18Aecc3C386',
    MERKLE_DISTRIBUTOR_ADDRESS: '', // not deployed
    LEND_POOL_ADDRESSES_PROVIDER: '0x95e84AED75EB9A545D817c391A0011E0B34EAf5C',
    LEND_POOL: '0xC0a03A1c61ECf436d2C6F3285992D7270a0e8a3F',
    LEND_POOL_LOAN: '0xaA04774b15FC5555DabC2b87Bdd6976B6C9f4Bc0',
    UI_POOL_DATA_PROVIDER: '0x65fe0C8E9d18b4959BF7f49B63796Ba9f4F9a0fb',
    WETH_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000001',
    PUNK_GATEWAY_ID: '0xADDE000000000000000000000000000000000000000000000000000000000002',
    WETH_ADDRESS: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
    USDT_ADDRESS: '0x53cEd787Ba91B4f872b961914faE013ccF8b0236',
    BEND_ADDRESS: '0xbeD1e8B430FD512b82A18cb121a8442F3889E505',
    CRYPTOPUNKS_ADDRESS: '0x554309B0888c37139D6E31aBAe30B4502915B5DB',
    WPUNKS_ADDRESS: '0x647dc527Bd7dFEE4DD468cE6fC62FC50fa42BD8b',
    BWETH: '0xD1E6d2B2D2205Ca77632c831bb8b5e468a270975',
    BDebtWETH: '0x9ca8943Fb310d2c06DFB4D27Ea3d566B4bdC0971',
    BUSDT: '0xb8e0FBce4BeCC7f28aAaa074D90d6050f32F0830',
    BDebtUSDT: '0x6a813E393614dDA1086f6d743739186eA92B3f42',
    BEND_COMPETITION: '', // not deployed
    BEND_PROTOCOL_INCENTIVES_CONTROLLER: '0x06A13f172ADce6fdE761c9A2Ff2d0ad50e9e38d0',
    BEND_ETH_UNISWAP_POOL: '',
    BEND_COLLECTOR: '0x337035aFA98B3B552e714B04347f51968486706B',
    BOUND_PUNK_GATEWAY: '0x1c3A772154D640bbc4879cFdB2C8310a9a8D795E',
    AIRDROP_DISTRIBUTION_CONTRACT: '0x59fb4BA8077a86846924C82F084fb8aeE3875df2',
    BEND_STAKE: '', // not deployed
    BEND_EXCHANGE: '0xBe410D495B843e4874a029580B7eAA6F3611107B',
    AUTHORIZATION_MANAGER: '0x6f9a28ACE211122CfD6f115084507b44FDBc12C7',
    REDEEM_NFT: '0x270f88923C751cE5A12f864582D12b2CF269F158',
    STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE: '0x7f803E6ae59094d5cFaD288C664b4401Dca8a068',
    ROYALTY_FEE_MANAGER: '0xC6589e63Ed7CfD17B2E7620d2d6a81ccF13088f7',
    DOWNPAYMENT: '0xD6ED460Dea089C07DA8ca7d292BfF830C6A294A9',
    DOWNPAYMENT_BEND_EXCHANGE_ADAPTER: '0xeC099722519dCe224Ad096781ba2791032003D3e',
    DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER: '', // not deployed
    DOWNPAYMENT_PUNK_ADAPTER: '0x52f51701ed1B560108fF71862330f9C8b4a15c8e',
    DOWNPAYMENT_SEAPORT_ADAPTER: '0xa1d0e0Ac6D1300F47caC9083b23D07F62bB1F833',
    DOWNPAYMENT_X2Y2_ADAPTER: '', // not deployed
    APECOIN_ADDRESS: '0x88a2B5Cb33dF5cf2b06214d55E2e26d8Fe418aE6',
    APECOIN_ADDRESS_MAINNET: '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
    BEND_APE_STAKING_ADDRESS: '0xA4aa1fc12FeDDD29d8b1393A700a17BE2f891231',
    STAKE_MANAGER_ADDRESS: '0x43e451A231C9013dE0231C31FF556766df5E954F',
    BAYC_ADDRESS: '0xE15A78992dd4a9d6833eA7C9643650d3b0a2eD2B',
    MAYC_ADDRESS: '0xD0ff8ae7E3D9591605505D3db9C33b96c4809CDC',
    BAKC_ADDRESS: '0xE8636AFf2F1Cf508988b471d7e221e1B83873FD9',
    APECOIN_STAKING_ADDRESS: '0xeecE96DD81B84c8a1670Cb5FB04D8386D61C1333',
    BEND_APECOIN_ADDRESS: '0x8c537FfC4417Ca7cf0C0B9c8F468c7A77117845E',
    OTHERDEED_ADDRESS: '0x3e9FE826da6Be171EE99eaFb40a97754997852BC',
    WKODA_ADDRESS: '0x675B54905Ae457D9B7BcA72B58a7CC33AD3AB514',
    KODA_GATEWAY: '0x45F617abbD8A9996b4feaf300F405b9b9407259B',
    APE_ETH_UNISWAP_POOL: '0xAc4b3DacB91461209Ae9d41EC517c2B9Cb1B7DAF', // mainnet address
    DELEGATE_CASH_ADDRESS: '0x00000000000076A84feF008CDAbe6409d2FE638B',
    LEND_POOL_SWAP_ADDRESS: '0x3ea00A4A0E4Ba9a549304C5681988c5bb04dEfBe',
    NATIVE_ORG_API_KEY: '',
    WBTC_ADDRESS: '0x8667bfB88fAcb152ec42eB4D50A63B563f2A10A1',
    WSTETH_ADDRESS: '0xB82381A3fBD3FaFA77B3a7bE693342618240067b'
  }
}

export const OTHERDEED_ADDRESS = process.env.NEXT_PUBLIC_OTHERDEED_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].OTHERDEED_ADDRESS
export const WKODA_ADDRESS = process.env.NEXT_PUBLIC_WKODA_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WKODA_ADDRESS
export const KODA_GATEWAY = process.env.NEXT_PUBLIC_KODA_GATEWAY || ADDRESSES[DEFAULT_CHAIN_ID].KODA_GATEWAY

export const BEND_APECOIN_ADDRESS = process.env.NEXT_PUBLIC_BEND_APECOIN_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_APECOIN_ADDRESS
export const APECOIN_STAKING_ADDRESS = process.env.NEXT_PUBLIC_APECOIN_STAKING_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].APECOIN_STAKING_ADDRESS
export const BEND_APE_STAKING_ADDRESS = process.env.NEXT_PUBLIC_BEND_APE_STAKING_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_APE_STAKING_ADDRESS
export const STAKE_MANAGER_ADDRESS = process.env.NEXT_PUBLIC_STAKE_MANAGER_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].STAKE_MANAGER_ADDRESS
export const APECOIN_ADDRESS = process.env.NEXT_PUBLIC_APECOIN_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].APECOIN_ADDRESS
export const APECOIN_ADDRESS_MAINNET = process.env.NEXT_PUBLIC_APECOIN_ADDRESS_MAINNET || ADDRESSES[DEFAULT_CHAIN_ID].APECOIN_ADDRESS_MAINNET
export const BAYC_ADDRESS = process.env.NEXT_PUBLIC_BAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BAYC_ADDRESS
export const MAYC_ADDRESS = process.env.NEXT_PUBLIC_MAYC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].MAYC_ADDRESS
export const BAKC_ADDRESS = process.env.NEXT_PUBLIC_BAKC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BAKC_ADDRESS
export const APECOIN_TESTNET_FAUCET = '' // not deployed

export const BEND_EXCHANGE = ADDRESSES[DEFAULT_CHAIN_ID].BEND_EXCHANGE
export const AUTHORIZATION_MANAGER = ADDRESSES[DEFAULT_CHAIN_ID].AUTHORIZATION_MANAGER
export const REDEEM_NFT = ADDRESSES[DEFAULT_CHAIN_ID].REDEEM_NFT
export const STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE = ADDRESSES[DEFAULT_CHAIN_ID].STRATEGY_STANDARD_SALE_FOR_FIXED_PRICE
export const ROYALTY_FEE_MANAGER = ADDRESSES[DEFAULT_CHAIN_ID].ROYALTY_FEE_MANAGER

export const DOWNPAYMENT = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT
export const DOWNPAYMENT_BEND_EXCHANGE_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_BEND_EXCHANGE_ADAPTER
export const DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_LOOKS_RARE_EXCHANGE_ADAPTER
export const DOWNPAYMENT_PUNK_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_PUNK_ADAPTER
export const DOWNPAYMENT_SEAPORT_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_SEAPORT_ADAPTER
export const DOWNPAYMENT_X2Y2_ADAPTER = ADDRESSES[DEFAULT_CHAIN_ID].DOWNPAYMENT_X2Y2_ADAPTER

export const FEE_DISTRIBUTOR_ADDRESS = process.env.NEXT_PUBLIC_FEE_DISTRIBUTOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].FEE_DISTRIBUTOR_ADDRESS
export const VE_BEND_ADDRESS = process.env.NEXT_PUBLIC_VE_BEND_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].VE_BEND_ADDRESS

export const MERKLE_DISTRIBUTOR_ADDRESS = process.env.NEXT_PUBLIC_MERKLE_DISTRIBUTOR_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].MERKLE_DISTRIBUTOR_ADDRESS
export const LEND_POOL_ADDRESSES_PROVIDER = process.env.NEXT_PUBLIC_LEND_POOL_ADDRESSES_PROVIDER || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL_ADDRESSES_PROVIDER
export const LEND_POOL = process.env.NEXT_PUBLIC_LEND_POOL || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL
export const LEND_POOL_LOAN = process.env.NEXT_PUBLIC_LEND_POOL_LOAN || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL_LOAN
export const UI_POOL_DATA_PROVIDER = process.env.NEXT_PUBLIC_UI_POOL_DATA_PROVIDER || ADDRESSES[DEFAULT_CHAIN_ID].UI_POOL_DATA_PROVIDER

export const WETH_GATEWAY_ID = process.env.NEXT_PUBLIC_WETH_GATEWAY_ID || ADDRESSES[DEFAULT_CHAIN_ID].WETH_GATEWAY_ID
export const PUNK_GATEWAY_ID = process.env.NEXT_PUBLIC_PUNK_GATEWAY_ID || ADDRESSES[DEFAULT_CHAIN_ID].PUNK_GATEWAY_ID

export const WETH_ADDRESS = process.env.NEXT_PUBLIC_WETH_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WETH_ADDRESS
export const USDT_ADDRESS = process.env.NEXT_PUBLIC_USDT_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].USDT_ADDRESS
export const BEND_ADDRESS = process.env.NEXT_PUBLIC_BEND_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].BEND_ADDRESS
export const CRYPTOPUNKS_ADDRESS = process.env.NEXT_PUBLIC_CRYPTOPUNKS_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].CRYPTOPUNKS_ADDRESS
export const WPUNKS_ADDRESS = process.env.NEXT_PUBLIC_WPUNKS_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WPUNKS_ADDRESS

export const BWETH = process.env.NEXT_PUBLIC_BWETH || ADDRESSES[DEFAULT_CHAIN_ID].BWETH
export const BDebtWETH = process.env.NEXT_PUBLIC_BDebtWETH || ADDRESSES[DEFAULT_CHAIN_ID].BDebtWETH

export const BUSDT = process.env.NEXT_PUBLIC_BUSDT || ADDRESSES[DEFAULT_CHAIN_ID].BUSDT
export const BDebtUSDT = process.env.NEXT_PUBLIC_BDebtUSDT || ADDRESSES[DEFAULT_CHAIN_ID].BDebtUSDT

export const WBTC_ADDRESS = process.env.NEXT_PUBLIC_WBTC_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WBTC_ADDRESS
export const WSTETH_ADDRESS = process.env.NEXT_PUBLIC_WSTETH_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].WSTETH_ADDRESS

export const BEND_COMPETITION = process.env.NEXT_PUBLIC_BEND_COMPETITION || ADDRESSES[DEFAULT_CHAIN_ID].BEND_COMPETITION
export const BEND_PROTOCOL_INCENTIVES_CONTROLLER =
  process.env.NEXT_PUBLIC_INCENTIVE_CONTROLLER || ADDRESSES[DEFAULT_CHAIN_ID].BEND_PROTOCOL_INCENTIVES_CONTROLLER

export const BEND_ETH_UNISWAP_POOL = process.env.NEXT_PUBLIC_BEND_ETH_UNISWAP_POOL || ADDRESSES[DEFAULT_CHAIN_ID].BEND_ETH_UNISWAP_POOL
export const BEND_COLLECTOR = process.env.NEXT_PUBLIC_BEND_COLLECTOR || ADDRESSES[DEFAULT_CHAIN_ID].BEND_COLLECTOR
export const BOUND_PUNK_GATEWAY = process.env.NEXT_PUBLIC_BOUND_PUNK_GATEWAY || ADDRESSES[DEFAULT_CHAIN_ID].BOUND_PUNK_GATEWAY

export const AIRDROP_DISTRIBUTION_CONTRACT = process.env.NEXT_PUBLIC_AIRDROP_DISTRIBUTION_CONTRACT || ADDRESSES[DEFAULT_CHAIN_ID].AIRDROP_DISTRIBUTION_CONTRACT

export const BEND_STAKE = process.env.NEXT_PUBLIC_BEND_STAKE || ADDRESSES[DEFAULT_CHAIN_ID].BEND_STAKE

export const DELEGATE_CASH_ADDRESS = process.env.NEXT_PUBLIC_DELEGATE_CASH_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].DELEGATE_CASH_ADDRESS

export const LEND_POOL_SWAP_ADDRESS = process.env.NEXT_PUBLIC_LEND_POOL_SWAP_ADDRESS || ADDRESSES[DEFAULT_CHAIN_ID].LEND_POOL_SWAP_ADDRESS
export const NATIVE_ORG_API_KEY = process.env.NEXT_PUBLIC_NATIVE_ORG_API_KEY || ADDRESSES[DEFAULT_CHAIN_ID].NATIVE_ORG_API_KEY

export const MAX_BORROW_MULTIPLIER = 0.99

export const ENS_REGISTRAR_ADDRESSES = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'

export const UNISWAP_FEE = 0.003
export const LENDPOOL_SWAP_SLIPPAGE = 100
export const LENDPOOL_UNISWAP_FEE = 3000 // 1% == 10000, 0.3% == 3000, 0.01% == 100

export const DEFAULT_ADDED_GAS_CALC_PERCENT = 20
export const RECOMMENDED_MIN_GAS_LIMIT = {
  LendPool: {
    deposit: 300000,
    withdraw: 200000,
    borrow: 1000000,
    repay: 700000,
    auction: 500000,
    redeem: 500000,
    liquidate: 700000
  },
  WETHGateway: {
    depositETH: 350000,
    withdrawETH: 400000,
    borrowETH: 1000000,
    repayETH: 700000,
    auctionETH: 550000,
    redeemETH: 550000,
    liquidateETH: 700000
  },
  PunkGateway: {
    borrowETH: 1200000,
    repayETH: 900000,
    auctionETH: 650000,
    redeemETH: 650000,
    liquidateETH: 900000
  }
}

export const maxBendSupply = '10000000000000000000000000000' // in wei

export {
  AIRDROP_DISTRIBUTION_CONTRACT_ABI,
  BEND_COMPETITION_ABI,
  BEND_PROTOCOL_INCENTIVES_CONTROLLER_ABI,
  BEND_STAKE_ABI,
  BNFT_ABI,
  BOUND_PUNK_GATEWAY_ABI,
  CRYPTO_PUNKS_MARKET_ABI,
  DEBT_TOKEN_ABI,
  DELEGATE_CASH_ABI,
  EIP2981_ABI,
  ENS_ABI,
  ENS_PUBLIC_RESOLVER_ABI,
  ERC721_ABI,
  FEE_DISTRIBUTOR_ABI,
  KODA_WRAPPER_ABI,
  LEND_POOL_ABI,
  LEND_POOL_ADDRESS_PROVIDER_ABI,
  LEND_POOL_LOAN_ABI,
  LEND_POOL_SWAP_ABI,
  MERKLE_DISTRIBUTOR_ABI,
  MULTI_CALL_NONPAYABLE_ABI,
  PUNK_GATEWAY_ABI,
  UI_POOL_DATA_PROVIDER_ABI,
  UNISWAP_PAIR_ABI,
  VE_BEND_ABI,
  WETH_ABI,
  WETH_GATEWAY_ABI,
  WRAPPED_PUNKS_ABI
}
